<template>
        <v-container fluid fill-height>
        <v-layout
          justify-center
          align-center column
        >    
<v-img
  lazy-src="../assets/UK_Logo_Transparent_Bak_Svart_653px.png"
  max-height="150"
  max-width="150"
  src="../assets/UK_Logo_Transparent_Bak_Svart_653px.png"
></v-img>

      <v-col md="8">
    <v-img
  lazy-src="@/assets/Ulrikas_E-learning_info_om_kurs.jpg"

  src="@/assets/Ulrikas_E-learning_info_om_kurs.jpg"
></v-img>
    <v-img
  lazy-src="@/assets/Ulrikas_E-learning_info_om_kurs2.jpg"

  src="@/assets/Ulrikas_E-learning_info_om_kurs2.jpg"
></v-img>
 <v-row no-gutters style="margin-top:20px">
  <v-col md="6" xs="12">
        <v-btn v-if="!user" class="black--text text--lighten-3"
    rounded
    
    color="secondary"
  
  elevation="2"
    to="/login"
>Logga in</v-btn>

  


    </v-col>
      <v-col md="6" xs="12">
           <v-btn v-if="!user" class="black--text text--lighten-3"
    rounded
    
    color="secondary"
    to="register"
  
  elevation="2"
>Registrera</v-btn>
    </v-col>
   
 </v-row>
    </v-col>
    
       
      

        </v-layout>
      </v-container>
</template>

<script>
// @ is an alias to /src

import {mapState} from 'vuex'
export default {
  name: 'Home',
  components: {
  },
   computed: {
    ...mapState(['user']),
    displayName () {
      return !this.user ? this.user.email : '';
    }
    
  },
   watch: {
    user (auth) {
      if(auth){
        this.$router.replace("menu")
      }
    }},
}
</script>
